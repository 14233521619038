import React, { Fragment, useState, useContext, useRef  } from "react";
import { LoadingContext } from "../context/LoadingContext";
import CMMI from '../../images/home/CMMI5-2024.png';
import CO from '../../images/ourDNA/CO.png';
import ISO9001 from '../../images/ourDNA/ITICOL.png';
import TRANSFORMACION from '../../images/ourDNA/TRANSFORMACION.jpg';
import ISOBV from '../../images/ourDNA/ISO-BureauVeritas.png';
import MPG from '../../images/ourDNA/MicrosoftPartnerGold2.png';
import i18n from "i18next";
import Slider from "react-slick"

const Certification = ({}) => {
    const { windowWidth } = useContext(LoadingContext);
    const carouselRef = useRef(null)
    const imagesArr = [
        {
            image: CMMI,  
            texto: "2",
        },
        {
            image: ISOBV,
            texto: "1",
        },
        {
            image: MPG,
            texto: "2",
        },
    ];

    const imagesArr2 = [
      {
          image: ISO9001,
          texto: "1",
      },
      {
          image: CO,
          texto: "2",
      },
      {
          image: TRANSFORMACION,
          texto: "1",
      },
  ];

  const imagesArr3 = [
    {
        image: CMMI,  
        texto: "2",
    },
    {
        image: ISOBV,
        texto: "1",
    },
    {
        image: MPG,
        texto: "2",
    },
    {
          image: ISO9001,
          texto: "3",
      },
      {
          image: CO,
          texto: "3",
      },
      {
          image: TRANSFORMACION,
          texto: "1",
      },
  ];

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    
  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerCertification">
              
              {windowWidth >= 1025 ?  
              <div className="flip-card-inner2  mt-24 mb-24">
              <div className="mobile:w-full mobile:pl-16 mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 mobile:pr-16">
           
                    <p className="titleCompanies">{i18n.t('OurExperienceWhyUs.titleCerti')}</p>              
        
                  </div>
                  
                  <div className="flex targetExperts mt-12 justifyStyle">
                    {imagesArr.map((item, i) => {
                        return (
                    <div className='contentTargetExperts '>
                            <div className='m-auto'>
                            <img src={item.image} alt="...." className={item.texto == 2 ? 'imgCardCertification5' : 'imgCardExpert'}/>
                            </div>

                </div>)
                 }) 
                 }
              </div>

              <div className="flex targetExperts mt-12 justifyStyle">
                    {imagesArr2.map((item, i) => {
                        return (
                    <div className='contentTargetExperts '>
                            <div className='m-auto'>
                            <img src={item.image} alt="....." className={item.texto == 2 ? 'imgCardCertification5' : 'imgCardExpert'}/>
                            </div>

                </div>)
                 }) 
                 }
              </div>
                   
                             
              </div>:
              <div className="flip-card-inner2  mt-24 mb-24">
              <div className="mobile:w-full  mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 mobile:pr-16">
           
                    <p className="titleCompanies">{i18n.t('OurExperienceWhyUs.titleCerti')}</p>              
        
                  </div>
                  
                  <div className=" mt-12 justifyStyle">
                  <Slider
                      ref={carouselRef}
                      {...settings}
                      className="rounded-sm  mx-2 py-10"
                    >
                    {imagesArr3.map((item, i) => {
                        return (
                    <div className='contentTargetExpertsMobile2 '>
                            <div className='m-auto'>
                            <img src={item.image} alt="...." className={item.texto == 2 ? 'imgCardCertification3': item.texto == 3 ? 'imgCardCertification4' : 'imgCardCertification22'}/>
                            </div>

                </div>)
                 }) 
                 }
              </Slider>
              </div>
                   
                             
              </div>
              
              }
             
            </div>

  );
};

export default Certification;
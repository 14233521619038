import React, { useContext, useState } from 'react';
import i18n from "i18next";
import Slider from "react-slick";
import { LoadingContext } from '../../components/context/LoadingContext';
import CardValue from './CardValue';

import iconRecursion from "./../../images/ourDNA/Recursion.png"
import iconInnovation from "./../../images/ourDNA/Innovation.png";
import iconResponsability from "./../../images/ourDNA/Responsability.png";
import iconComminment from "./../../images/ourDNA/Commitment.png";
import iconModesty from "./../../images/ourDNA/Modesty.png";
import iconTeamWork from "./../../images/ourDNA/Team_Work.png";

const valueInitial = [
  {
      image: iconRecursion,
      texto: "Recursion",
      textSpanish: "Recursividad",
  },
  {
      image: iconInnovation,
      texto: "Innovation",
      textSpanish: "Innovación",
  },
  {
      image: iconResponsability,
      texto: "Responsibility",
      textSpanish: "Responsabilidad",
  },
  {
    image: iconComminment,
    texto: "Commitment",
    textSpanish: "Compromiso",
    },
    {
      image: iconModesty,
      texto: "Modesty",
      textSpanish: "Modestia",
  },
  {
    image: iconTeamWork,
    texto: "Team Work",
    textSpanish: "Trabajo en equipo",
  },
];

const valuesOpen = [
  {
      title: "Recursion",
      textSpanish: "Recursividad",
      texto: "We work under lean principles to deliver solutions in an effective, efficient, and empathetic way.",
      texto2: "Trabajamos bajo principios lean para entregar soluciones de manera eficaz, eficiente y empática.",
      color: "#00ACEE",
  },
  {
      title: "Innovation",
      textSpanish: "Innovación",
      texto: "We constantly test new technologies to provide cutting-edge solutions.",
      texto2: "Constantemente probamos nuevas tecnologías para brindar soluciones de vanguardia.",
      color: "#6D4EF6",
  },
  {
      title: "Responsibility",
      textSpanish: "Responsabilidad",
      texto: "We take ownership of our actions and decisions, ensuring accountability in delivering high-quality results and meeting our commitments.",
      texto2: "Nos hacemos responsables de nuestras acciones y decisiones, garantizando la rendición de cuentas en la entrega de resultados de alta calidad y el cumplimiento de nuestros compromisos.",
      color: "#85D25D",
  }
];

  const valuesOpen2 = [
  {
    title: "Commitment",
    textSpanish: "Compromiso",
    texto: "We comply with what we promise and commit ourselves to achieving the agreement.",
    texto2: "Cumplimos con lo que prometemos y nos comprometemos a lograr lo pactado.",
    color: "#CC3271",
    },
    {
      title: "Modesty",
      textSpanish: "Modestia",
      texto: "We deliver the best IT talent in Latam with fair and transparent rates in which win-win relationships are established.",
      texto2: "Entregamos el mejor talento TI de Latam con tarifas justas y transparentes en las que se establecen relaciones ganar-ganar.",
      color: "#FF8500",
  },
  {
    title: "Team Work",
    textSpanish: "Trabajo en equipo",
    texto: "We prioritize collaboration, communication, and mutual support to leverage the diverse talents and perspectives within our team.",
    texto2: "Priorizamos la colaboración, la comunicación y el apoyo mutuo para aprovechar los diversos talentos y perspectivas dentro de nuestro equipo.",
    color: "#6D4EF6",
  },
];

const OurValues = ({ div }) => {

    const { windowWidth } = useContext(LoadingContext);
    const [closeModal, setCloseModal] = useState(true);
    const [profileSelected, setProfileSelected] = useState(0);
    const imgStyle2 = {
        maxWidth: '6rem',
        maxHeight: '5.5rem'
    };


    const settings = {
        dots: true,
        dotsClass: 'slick-dots blue-dark-slide-dots hidden-desktop__dot',
        infinite: true,
        autoPlay: true,
        autoPlaySpeed: 3000,
        slidesToShow: 1,
        initialSlide: 0,
        speed: 500,
      };
    
    

    return (
        <div className={`desktop:p-20 pl-6 pb-6 pr-6 pt-24 ${div}`}>
            
            { windowWidth >= 1025 ? <>
                <div className='flex'>
                <div className='countTextCountMobile'>
                    <p className='textAboutUs'>{i18n.t('OurExperienceWhyUs.subtitleValue')}</p>
                    <p className='titleOurServices'>{i18n.t('OurExperienceWhyUs.titleValue')}</p> 
                    </div>
            </div>

            <p className="textCoreSolutionsSeccion desktop:text-20 mobile:text-16 desktop:text-left  mb-8 mobile:px-6">
            {i18n.t('OurExperienceWhyUs.textValue')}
            </p>
            </>: <>
            <div className=''>
                <div className='countTextCountMobile'>
                    <p className='subTitleOurServices'>{i18n.t('OurExperienceWhyUs.subtitleValue')}</p>
                    <p className='titleOurServices'>{i18n.t('OurExperienceWhyUs.titleValue')}</p> 
                    </div>
                         
            </div>

            <p className="textCoreSolutionsMobile eventRightTimeLineMobile desktop:text-20 mobile:text-16 desktop:text-left  mb-8 mobile:px-6">
            {i18n.t('OurExperienceWhyUs.textValue')}
            </p>

            </>

            }

          <div className={windowWidth > 1700 ?"desktop:flex w-full ": "desktop:flex w-full"}>
            {valueInitial.slice(0,3).map((item, key) => {
					return (
						<CardValue key={key} profileIcon={valuesOpen[key]} profile={item} setCloseModal={setCloseModal} setProfileSelected={setProfileSelected} />
					);
				    })} 

            </div>
             <div className="desktop:flex justifyRight w-full">
            {valueInitial.slice(3,6).map((item, key) => {
					return (
						<CardValue key={key} profileIcon={valuesOpen2[key]} profile={item} setCloseModal={setCloseModal} setProfileSelected={setProfileSelected} />
					);
				    })} 
            
            </div>
           
         
          
          <br/>
        </div>
    )
}



export default OurValues

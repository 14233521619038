import React, { useState, useContext, useEffect, useRef } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import arrowLeft from "./../../images/home/arrowLeft.jpg"
import arrowRigth from "./../../images/home/arrowRigth.jpg"
import socialMediaLinkedin from "./../../images/ourDNA/inImage.png"
import { LoadingContext } from "../context/LoadingContext"
import { Link } from "gatsby"

import Miguel from "./../../images/ourDNA/Miguel-Jimenez.png"
import Sebastian from "./../../images/ourDNA/SebastianJimenez.png"
import Santiago from "./../../images/ourDNA/Santiago-Jimenez.png"
import Yuli from "./../../images/ourDNA/YuliRios.png"
import Diana from "./../../images/ourDNA/DianaPulido.png"
import Luisa from "./../../images/ourDNA/LuisaGalindo.png"
import Cristian from "./../../images/ourDNA/CristianAbril.png"
import Julian from "./../../images/ourDNA/JulianBonilla.png"
import Hernan from "./../../images/ourDNA/HernanFagua.png"
import Gabriela from "./../../images/ourDNA/GabrielaOrtiz.png"
import JuanAguirre from "./../../images/ourDNA/Juan-Aguirre.png"
import Angelica from "./../../images/ourDNA/AngelicaCamacho.png"
import Isabela from "./../../images/ourDNA/IsabelaMogollón.png"
import Maira from "./../../images/ourDNA/MairaLopez.png"
import Marcela from "./../../images/ourDNA/MarcelaCamargo.png"

const OurTeam = ({}) => {
  const { windowWidth } = useContext(LoadingContext)
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null)
  const [hoveredIndex, setHoveredIndex] = useState(-1)

  const team = [
    {
      image: Miguel,
      name: "Miguel Jiménez",
      position: "CEO",
      posEs: "CEO",
      linkedIn:
        "https://www.linkedin.com/in/miguel-angel-jimenez-nu%C3%B1ez-60a76a7/",
    },
    {
      image: Sebastian,
      name: "Sebastián Jiménez",
      position: "Country Manager",
      posEs: "Gerente",
      linkedIn:
        "https://www.linkedin.com/in/sebastian-jimenez-pedraza-37884093/",
    },
    {
      image: Santiago,
      name: "Santiago Jiménez",
      position: "CIO",
      posEs: "Director de Innovación",
      linkedIn: "https://www.linkedin.com/in/santiago-jim%C3%A9nez-23797616b/",
    },
    {
      image: Diana,
      name: "Diana Pulido",
      position: "Chief Operations Officer",
      posEs: "Director de Operaciones",
      linkedIn: "https://www.linkedin.com/in/dianapulidovillarraga/",
    },
    {
      image: Isabela,
      name: "Isabela Mogollón",
      position: "Talent Acquisition Lead IT",
      posEs: "Líder de Adquisición de Talento TI",
      linkedIn: "https://www.linkedin.com/in/isabela-mogoll%C3%B3n-caicedo-27232b22b/",
    },
    {
      image: Maira,
      name: "Maira López",
      position: "Human Talent Lead",
      posEs: "Líder de Talento Humano",
      linkedIn: " https://www.linkedin.com/in/maira-lopez-3405001a8/",
    },
    {
      image: Marcela,
      name: "Marcela Camargo",
      position: "OHS Lead",
      posEs: "Líder de Salud y Seguridad en el trabajo",
      linkedIn: "https://www.linkedin.com/in/paola-marcela-camargo-4575a724b/",
    },
    {
      image: Luisa,
      name: "Luisa Galindo",
      position: "Projects & Performance Improvement Director",
      posEs: "Director de Calidad y Proyectos",
      linkedIn: "https://www.linkedin.com/in/luisagalindo/",
    },
    {
      image: Cristian,
      name: "Cristian Abril",
      position: "Performance Improvement Lead",
      posEs: "Líder de Evaluación y Mejora",
      linkedIn: "https://www.linkedin.com/in/cristian-abril-35776b188/",
    },
    {
      image: Julian,
      name: "Julian Bonilla",
      position: "Infrastructure Lead",
      posEs: "Líder de Infraestructura",
      linkedIn: "https://www.linkedin.com/in/julian-bonilla-02a466180/",
    },
    {
      image: Hernan,
      name: "Hernán Fagua",
      position: "Information Security Leader",
      posEs: "Líder de Seguridad de la Información",
      linkedIn: "https://www.linkedin.com/in/hernan-fagua-0428471b5/",
    },
    {
      image: JuanAguirre,
      name: "Juan Aguirre",
      position: "Design Lead",
      posEs: "Líder de Diseño",
      linkedIn:
        "https://www.linkedin.com/in/juan-carlos-aguirre-forero-a61b971a8/",
    },
    {
      image: Yuli,
      name: "Yuly Ríos",
      position: "Finance Director",
      posEs: "Director Financiero",
      linkedIn: "https://www.linkedin.com/in/andrea-rios-tapiero-2672a3211/",
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    autoPlay: true,
    loop: true,
    arrows: false,
    autoPlaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    speed: 1000,
    fade: false,
    dotsClass: "slick-dots blue-dark-slide-dots--small hidden-desktop__dot",
    transition: "all 0.2s",
  }

  const settingsMobile = {
    dots: false,
    infinite: true,
    autoPlay: true,
    loop: true,
    arrows: false,
    autoPlaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 1000,
    fade: false,
    dotsClass: "slick-dots blue-dark-slide-dots--small hidden-desktop__dot",
    transition: "all 0.2s",
  }

  const goToSlide = index => {
    setCurrentIndex(index)
    if (carouselRef.current) {
      if (index < currentIndex) {
        carouselRef.current.slickPrev()
      } else if (index > currentIndex) {
        carouselRef.current.slickNext()
      }
    }
  }

  const handleMouseOver = index => {
    setHoveredIndex(index)
  }

  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-20 mobile:pl-4 flip-card pt-12 pb-20">
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 desktop:ml-16 mobile:ml-4 mt-24">
          <div className="flex ">
            <div>
              <p className="subTitleOurServices">
                {i18n.t("OurExperienceWhyUs.subtitleMeet")}
              </p>
              <p
                className={
                  i18n.language == "en"
                    ? "titleOurServices contentTitleTeams"
                    : "titleOurServices contentTitleTeamsSpanish"
                }
              >
                {i18n.t("OurExperienceWhyUs.titleMeet")}
              </p>
            </div>
            <div className="desktop:flex desktop:pr-12 ml-auto ">
              <div className="desktop:flex ml-auto gap-20 mobile:mt-8">
                <button onClick={() => goToSlide(currentIndex - 1)}>
                  <img src={arrowLeft} alt="left" className="mt-1" />
                </button>
                <button onClick={() => goToSlide(currentIndex + 1)}>
                  <img src={arrowRigth} alt="right" />
                </button>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <Slider {...settings} ref={carouselRef}>
              {team.map((item, i) => {
                return (
                  <div key={i.toString()}>
                    <div className="flex justify-end transform -translate-x-3 translate-y-10 bigdesktop:translate-y-16">
                      <a href={item.linkedIn} target="_blank">
                        <img
                          src={socialMediaLinkedin}
                          alt="socialMedia"
                          className="w-4 bigdesktop:w-5"
                        />
                      </a>
                    </div>
                    <img src={item.image} alt={item.name} />
                    <div className="flex pl-12  ">
                      <div className="mt-4 ml-2 text-left ">
                        <p className="pr-2 pl-2 textNameTeams ">{item.name}</p>
                        <p className="pr-2 pl-2 textPosicionTeams">
                          {i18n.language == "en" ? item.position : item.posEs}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <p className="subTitleOurServices">
              {i18n.t("OurExperienceWhyUs.subtitleMeet")}
            </p>
            <p
              className={
                i18n.language == "en"
                  ? "titleOurServices contentTitleTeams"
                  : "titleOurServices contentTitleTeamsSpanish w-11/12"
              }
            >
              {i18n.t("OurExperienceWhyUs.titleMeet")}
            </p>
          </div>
          <div className="mt-5">
            <Slider {...settingsMobile} ref={carouselRef}>
              {team.map((item, i) => {
                return (
                  <div key={i.toString()}>
                    <div className="pr-5">
                      <div className="flex justify-end transform pr-8 translate-y-10">
                        <a href={item.linkedIn} target="_blank">
                          <img
                            src={socialMediaLinkedin}
                            alt="socialMedia"
                            className="w-4"
                          />
                        </a>
                      </div>
                      <img src={item.image} alt={item.name} />
                      <div className="flex pl-12  ">
                        <div className="mt-4 ml-2 text-left ">
                          <p className="pr-2 pl-2 textNameTeams ">
                            {item.name}
                          </p>
                          <p className="pr-2 pl-2 textPosicionTeams">
                            {i18n.language == "en" ? item.position : item.posEs}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
          <div className="flex justify-center gap-16  mt-5">
            <button onClick={() => goToSlide(currentIndex - 1)}>
              <img src={arrowLeft} alt="left" className="mt-1" />
            </button>
            <button onClick={() => goToSlide(currentIndex + 1)}>
              <img src={arrowRigth} alt="right" />
            </button>
          </div>
        </div>
      )}

      <Link to={"/contact-us"}>
        <button
          className={
            windowWidth >= 1025
              ? "contentButtomViewAllServices mobile:mt-8 desktop:mt-20 desktop:ml-4 desktop:mr-4 widthMaxcontent"
              : "w-full contentButtomViewAllServices mobile:mt-8"
          }
          style={{ alignSelf: "center" }}
        >
          <p className="textButtomWorkUS font-sofiaPro-bold font-bold">
            {i18n.t("OurExperienceWhyUs.bottomMeet")}
          </p>
        </button>
      </Link>
    </div>
  )
}

export default OurTeam

import React, { Fragment, useState, useContext  } from "react";
import image1 from '../../images/ourDNA/placeholder01.jpg'
import image2 from '../../images/ourDNA/placeholder02.jpg'
import image3 from '../../images/ourDNA/placeholder4.jpg'
import { LoadingContext } from "../context/LoadingContext";
import i18n from "i18next";

const BannerOurExperience = ({ bannerImageURL, bannerImageContent}) => {

    const [dinamicHeight, setDinamicHeight] = useState(0);
    const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0);
    const { windowWidth } = useContext(LoadingContext);

    const ourCulture = [image3,image2,image1];


    return (
        <Fragment>
            <div className="container-banner-main">

                <div className="shared-banner-container">
                {windowWidth >= 1025 ?  
                            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipal">
                       
                            <div ref={el => {
                                if (el) {
                                    const resizeObserver = new ResizeObserver(entries => {
                                        setDinamicHieghtColbanner(entries[0].contentRect.height);
                                    });
                                    resizeObserver.observe(el);
                                }
                            }}
                                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                                {bannerImageContent}
                            </div>
                            <div  className="shared-banner-imageHome mobile:ml-0">
                                <div className="bannerExperienceContentStyle">
                                <img src={bannerImageURL} alt="Imagen PopUp" className="imgBannerExperience" />
                                
                                </div>
                            
                         </div>
                        </div>:
                        <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalMobile">
                       
                        <div ref={el => {
                            if (el) {
                                const resizeObserver = new ResizeObserver(entries => {
                                    setDinamicHieghtColbanner(entries[0].contentRect.height);
                                });
                                resizeObserver.observe(el);
                            }
                        }}
                            className="shared-banner-content-btnAcademy m-4 desktop:mt-4 mobile:mt-4 pb-48">
                            {bannerImageContent}
                        </div>
                        <div  className="shared-banner-imageHomeMobile mobile:ml-0">
                            <div className="bannerContentStyleMobile">
                            
                            </div>
                        
                     </div>
                    </div>
                  }
                    
                    {windowWidth >= 1025 ?
                   <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 ml-4">
                    <div className="contentWhyUs flex">
                    <div className="eventRightTimeLineMobile">
                    <p className='textAboutUs'>{i18n.t('OurExperienceWhyUs.subtitle')}</p>  
                    <p className='titleOurServices'>{i18n.t('OurExperienceWhyUs.title')}</p> 
                    </div>
                       
                            <p className="text_our_experience">
                            {i18n.t('OurExperienceWhyUs.text')}
                        </p>     
                          
                        
                    </div> 
                    <div className="w-full contentApply flex ml-8 mt-10 mr-16">
                        {ourCulture.map((item, i) => {
                             return (
                        <div className='mr-6 '>
                                <img src={item} alt="...." className=""/>
                            </div>)
                            }) 

                        }
                    </div>

                    </div>:
                    <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 ml-4">
                    <div className="ml-4 mr-8">
                    <div className="eventRightTimeLineMobile">
                    <p className='textAboutUs'>{i18n.t('OurExperienceWhyUs.subtitle')}</p> 
                    <p className='titleOurServices'>{i18n.t('OurExperienceWhyUs.title')}</p> 
                    </div>
                       
                            <p className="text_our_experienceMobile">
                            {i18n.t('OurExperienceWhyUs.text')}
                        </p>     
                          
                        
                    </div> 
                    <div className="mt-10">
                        {ourCulture.map((item, i) => {
                             return (
                        <div className='mr-8 ml-4 mt-4'>
                                <img src={item} alt="...." className="w-full"/>
                            </div>)
                            }) 

                        }
                    </div>

                    </div>
                    
                    }


                  
                </div>
            </div>
        </Fragment>

    )
}

BannerOurExperience.defaultProps = {
    flag: true,
    clase: 'shared-banner-footer banner-text-white mt-8',
    style: {}
}

export default BannerOurExperience;
